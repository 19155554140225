import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import LoginPage from '../pages/LoginPage.vue';
import ProdutosPage from '../pages/ProdutosPage.vue';
import NotFoundPage from '../pages/NotFoundPage.vue';
import CategoriasPage from '../pages/CategoriasPage.vue';
import ClientesPage from '../pages/ClientesPage.vue';
import CadastroCliente from '../pages/CadastroCliente.vue';
import UsuariosPage from '../pages/UsuariosPage.vue';

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: HomePage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: UsuariosPage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/Produtos',
        name: 'ProdutosPage',
        component: ProdutosPage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/categorias',
        name: 'Categorias',
        component: CategoriasPage,
    },
    {
        path: '/clientes',
        name: 'Clientes',
        component: ClientesPage,
    },
    {
        path: '/CadastroCliente',
        name: 'CadastroCliente',
        component: CadastroCliente,
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/404',
        name: '404',
        component: NotFoundPage
    },
    // Outras rotas
    {
        path: '/:pathMatch(.*)*', // Captura todas as rotas não definidas
        redirect: { name: '404' } // Redireciona para a página de login
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('jwt'); // Verifica se há um token armazenado

    if (to.meta.requiresAuth && !isAuthenticated) {
        next({ name: 'Login' }); // Redireciona para a página de login se não autenticado
    } else {
        next(); // Permite a navegação se autenticado ou se a rota não requer autenticação
    }
});

export default router;